import { scroller } from 'react-scroll'

import navbarLogo from '../../assets/images/navbarLogo.png'

import { useNavigate } from 'react-router-dom';


const Navbar = (props) => {
  // console.log(props)
const navigate = useNavigate();
  return(
    <div
      style={{
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        // paddingTop: '3.2vh', 
        // paddingBottom: '3.2vh', 
        paddingTop: '2vh', 
        paddingBottom: '2vh', 
        position: 'fixed',
        zIndex: 1000000,
        // transform: props.scrollDir === 'down' && window.scrollY > window.innerHeight * 0.4 && props.mousePosition.y < window.innerHeight * 0.08 ? 'translateY(-100%)' : 'translateY(0%)',
        // transform: props.scrollDir === 'down' && window.scrollY > window.innerHeight * 0.4 ? 'translateY(-100%)' : 'translateY(0%)',
        transition: '.4s ease-in-out',
      }}
    >
      <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: 1000002}}>
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <div style={{marginRight: '2vw', paddingTop: '0.3vw'}}>
            <img src={navbarLogo} style={{width: '2.6vw'}}/>
          </div>
          <div style={{display: 'flex', gap: '2vw'}}>
            <div
              style={styles.sectionText}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo('thegame', {
                    duration: 1000,
                    smooth: true,
                    offset: -100
                  });
                }, 100);
                navigate('/');
              }}
            >
              {props.lang === 'en' ? 'THE GAME' : 'EL JUEGO'}
            </div>

            <div
              style={styles.sectionText}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo('features', {
                    duration: 1000,
                    smooth: true,
                    offset: -100
                  });
                }, 100);
                navigate('/');
              }}
            >
              {props.lang === 'en' ? 'FEATURES' : 'MECÁNICAS'}
            </div>

            {<div
              style={styles.sectionText}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo('hype', {
                    duration: 1000,
                    smooth: true,
                    offset: -100
                  });
                }, 100);
                navigate('/hype');
              }}
            >
              HYPE
            </div> }

            <div
              style={styles.sectionText}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo('about', {
                    duration: 1000,
                    smooth: true,
                    offset: -100
                  });
                }, 100);
                navigate('/about');
              }}
            >
              ABOUT US
            </div>
            {/* <div
              style={styles.sectionText}
              onClick={() => {
                scroller.scrollTo('team', {
                  duration: 1000,
                  smooth: true,
                })
              }}
            >
              {props.lang === 'en' ? 'TEAM' : 'EQUIPO'}
            </div> */}

            {/*<a
              style={styles.sectionText}
              href='https://medium.com/@oxyspace.app'
              target='_blank'
              rel='noopener noreferrer'
            >
              MEDIUM
            </a>*/}
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '2vw'}}>
          <div
            style={{
              ...styles.sectionText,
              cursor: 'pointer'
            }}
            onClick={() => props.setLang(prev => prev === 'en' ? 'es' : 'en')}
          >
            {props.lang.toUpperCase()}  
          </div>
          <a
            onClick={() => {
              scroller.scrollTo('subscribe', {
                duration: 1000,
                smooth: true,
              })
            }}
            style={{...styles.sectionText, backgroundColor: 'var(--light-blue)', padding: '.5vw .8vw', borderRadius: '.6vw', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', fontWeight: 800, fontSize: '.9rem', paddingTop: '.5vw', color: 'white'}}>
            {/* <div style={{width: '1.6vw', height: '1.6vw', marginRight: '.8vw'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width='100%' height='100%' viewBox="0 0 512 512" fill='var(--white)'>
                <path d="M448 32C465.7 32 480 46.33 480 64C480 81.67 465.7 96 448 96H80C71.16 96 64 103.2 64 112C64 120.8 71.16 128 80 128H448C483.3 128 512 156.7 512 192V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H448zM416 336C433.7 336 448 321.7 448 304C448 286.3 433.7 272 416 272C398.3 272 384 286.3 384 304C384 321.7 398.3 336 416 336z"/>
              </svg>
            </div>
            <div style={{paddingTop: '.2vw'}}>
              {props.lang === 'en' ? 'CONNECT' : 'CONECTAR'}
            </div> */}

            {props.lang === 'en' ? 'JOIN BETA' : 'ÚNETE BETA'}
          </a>
        </div>
      </div>
      <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: `rgba(7, 4, 26, ${Math.max(0, Math.min(.8, (props.windowOffset - window.innerHeight * 0.4) / window.innerHeight * 2))}`, backdropFilter: 'blur(10px)'}}/>
    </div>
  )
}

const styles = {
  sectionText: {
    fontWeight: '700',
    fontSize: '1vw',
    cursor: 'pointer',
  }
}

export default Navbar