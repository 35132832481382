import { useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Stars } from '@react-three/drei'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Landing from './home/Landing'
import Navbar from './home/Navbar'
import Thegame from './home/Thegame'
import Features from './home/Features'
import Subscribe from './home/Subscribe'
import AboutUs from './about/AboutUs'
import Hype from './home/Hype'
import Footer from './home/Footer' //Footer asset
import Socials from './home/Socials'

const HomeMobile = (props) => {
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en')

  useEffect(() => {
    localStorage.setItem('lang', lang)
  }, [lang])

  return (
    <div style={{width: '100vw', position: 'relative', overflow: 'hidden'}}>
      
      <Router>
        <Landing lang={lang} setLang={setLang} mobile={true} />
        <Thegame lang={lang} mobile={true} />
        <Features lang={lang} mobile={true}/>
        <Hype lang={lang}/>
        <Subscribe lang={lang} mobile={true} />
        <Footer lang={lang} mobile={true}/> 
      </Router>
    </div>
  )
}

export default HomeMobile