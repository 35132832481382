import { useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Stars } from '@react-three/drei'

import { useScrollDirection } from 'react-use-scroll-direction'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Landing from './home/Landing'
import Navbar from './home/Navbar'
import Thegame from './home/Thegame'
import Features from './home/Features'
import Subscribe from './home/Subscribe'
import AboutUs from './about/AboutUs'
import Hype from './home/Hype'
import Footer from './home/Footer'
import Socials from './home/Socials'

const Home = () => {
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en')
  
  // const [scrollDir, setScrollDir] = useState('up')
  // const {isScrollingDown, isScrollingUp, isScrolling} = useScrollDirection()
  // const [hasScrolled, setHasScrolled] = useState(false)

  const [windowOffset, setWindowOffset] = useState(0)

  // useEffect(() => {
  //   if(isScrollingUp){
  //     setScrollDir('up')
  //   }else if(isScrollingDown){
  //     setScrollDir('down')
  //   }else{
  //     setTimeout(() => {
  //       if(!hasScrolled){
  //         setScrollDir('down')
  //       }
  //       setHasScrolled(false)
  //     }, 4000)
  //   }
  // }, [isScrollingDown, isScrollingUp, hasScrolled])

  // useEffect(() => {
  //   if(!isScrolling){
  //     setHasScrolled(true)
  //   }
  // }, [isScrolling])

  useEffect(() => {
    const onScrollWheel = () => setWindowOffset(window.scrollY)
    document.addEventListener('wheel', onScrollWheel)
    return () => document.removeEventListener('wheel', onScrollWheel)
  }, [])

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const onMouseMove = (e) => setMousePosition({ x: e.clientX, y: e.clientY })
    document.addEventListener('mousemove', onMouseMove)
    return () => document.removeEventListener('mousemove', onMouseMove)
  }, [])

  useEffect(() => {
    localStorage.setItem('lang', lang)
  }, [lang])

  return (
    <div style={{width: '100vw', position: 'relative', overflow: 'hidden'}}>
      <Router>
        <Navbar lang={lang} setLang={setLang} windowOffset={windowOffset}/>
        <Routes>
          <Route path="/" element={<>
            <Landing lang={lang} />
            <Thegame lang={lang} windowOffset={windowOffset} />
            <Features lang={lang} />
            {/*<Roadmap lang={lang} mousePosition={mousePosition} /> */}
          </>} />
          <Route path="/about" element={<AboutUs lang={lang}/>} />
          <Route path="/hype" element={<Hype lang={lang}/>} />
        </Routes>
        <Subscribe lang={lang} />
        <Footer lang={lang} /> 
        <Socials />
      </Router>
    </div>
  )
}

export default Home