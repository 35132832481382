import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useEffect, useState } from "react";

import star from "../../assets/roadmap/favorito.png";

import { useInView } from "react-intersection-observer";

const Hype = (props) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      // Set the target date to May 6, 2023 at 6:00 PM
      const targetDate = new Date("June 19, 2023 20:00:00").getTime();
      // Get the current date and time
      const currentDate = new Date().getTime();
      // Calculate the remaining time
      const remainingTime = targetDate - currentDate;
      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
      );
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      // Format the countdown string
      //const countdownString = `${days} days ${hours}h ${minutes}m ${seconds}s`;
      const countdownString = `REVEALED`;
      // Update the countdown state
      setCountdown(countdownString);
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  const viewController = {
    main2: useInView({ threshold: 0.25, triggerOnce: false }),
  };
  return (
    <section
      id="hype"
      style={{
        // Define la fuente usada en esta seccon
        fontFamily: "'Averta'",

        // Ancho del contenedor ajustado al 80% del viewport
        width: "80vw",
        // Cambiado de 'height' a 'minHeight' para permitir que el contenedor crezca según el contenido
        minHeight: "50vh", // Cambiado a minHeight
        paddingTop: "20vh", // Espaciado superior del contenedor
        paddingBottom: "10vh", // Espaciado inferior del contenedor
        paddingRight: "10vw", // Espaciado derecho del contenedor
        paddingLeft: "10vw", // Espaciado izquierdo del contenedor
        // Cambiado de 'static' a 'relative' para evitar superposiciones
        position: "relative", // Cambiado a relative
        // Ajuste del z-index para controlar el apilamiento de los elementos
        zIndex: 10, // Ajuste del z-index
        // Uso de flexbox para el layout del contenedor
        display: "flex", // Añadido display flex
        // Para que los elementos hijos se apilen verticalmente
        flexDirection: "column", // Añadido flexDirection
        // Alinea los elementos hijos al inicio del contenedor
        justifyContent: "flex-start", // Cambiado a flex-start
        // Permite que el contenido se muestre completamente y no se recorte
        overflow: "visible", // Añadido overflow visible
        alignItems: "center",
        textTransform: "uppercase",
        textShadow: "rgb(0 0 0) 2px 2px 0px",
      }}
    >
      <div
        style={{
          fontWeight: "700",
          fontSize: "2.8rem",
          marginBottom: "4vh",
          textAlign: "center",
        }}
      >
        HYPE
      </div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#3d405c", color: "#ffae00" }}
          contentArrowStyle={{ borderRight: "7px solid grey" }}
          date={countdown}
          iconStyle={{ background: "#ffae00", color: "#fff" }}
          //icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">BIOME CINEMATIC</h3>
          <div style={{ color: "#e9e9e9" }}>
            <li>Enhanted Biome Video</li>
            <li>
              <a href="https://youtu.be/_EmrWW2KPqg">
                Click To see the cinematic
              </a>
            </li>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#404040", color: "#e9e9e9" }}
          contentArrowStyle={{ borderRight: "7px solid grey" }}
          date="SOON"
          iconStyle={{ background: "#959595", color: "#fff" }}
          //icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Teaser Release</h3>
          <div style={{ color: "#959595" }}>
            <li>Unleash the Adventure</li>
            <li>Be prepared</li>
            <li>COMING SOON</li>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#404040", color: "#e9e9e9" }}
          contentArrowStyle={{ borderRight: "7px solid grey" }}
          date="SOON"
          iconStyle={{ background: "#959595", color: "#fff" }}
          //icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">To be defined</h3>
          <p style={{ color: "#959595" }}>
            A countdown will show up when the before one is revealed
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#404040", color: "#e9e9e9" }}
          contentArrowStyle={{ borderRight: "7px solid grey" }}
          date="SOON"
          iconStyle={{ background: "#959595", color: "#fff" }}
          //icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">To be defined</h3>
          <p style={{ color: "#959595" }}>
            A countdown will show up when the before one is revealed
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </section>
  );
};

export default Hype;
