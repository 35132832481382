import { useState, useEffect, Suspense } from 'react'
import IScroll from 'iscroll';

import navbarLogo from '../../assets/images/navbarLogo.png'
import logoLanding from '../../assets/images/logoLanding.png'

import landingFondo from '../../assets/images/LandingMobile2.png'
import scroll from '../../assets/images/scroll.png'

import fondoSolo from '../../assets/images/fondoSolo.png'
import robotSolo from '../../assets/images/RobotSoloAnim.png'
import meteor1 from '../../assets/images/Meteor1.png'
import meteor2 from '../../assets/images/Meteor2.png'
import meteor3 from '../../assets/images/Meteor3.png'
import meteor4 from '../../assets/images/Meteor4.png'

import Bar1 from '../../assets/images/Bar1.svg'
import Bar2 from '../../assets/images/Bar2.svg'
import Bar3 from '../../assets/images/Bar3.svg'
import Bar4 from '../../assets/images/Bar4.svg'
import Bar5 from '../../assets/images/Bar5.svg'
import Bar6 from '../../assets/images/Bar6.svg'
import Bar7 from '../../assets/images/Bar7.svg'

import downArrow from '../../assets/images/downArrow.png'

import Socials from './Socials'

import styles from './Landing.module.css'
import { FunctionComponent } from "react";

import { Button } from "react-bootstrap";
import { scroller } from 'react-scroll'

const Bars = () => {
  return (
    <div style={{
      position: "absolute",
      top: "20%",
      left: "60.1%",
      width: "9vw",
      height: "20vh",
    }}>
      <img
        style={{
          position: "absolute",
          top: "23%",
          left: "40%",
          width: "25%",
        }}
        className={styles.bar7}
        src={Bar7}
      />
      <img
        style={{
          position: "absolute",
          top: "16%",
          left: "67%",
          width: "28%",
        }}
        className={styles.bar6}
        src={Bar6}
      />
      <img
        style={{
          position: "absolute",
          top: "40%",
          left: "54%",
          width: "28%",
        }}
        className={styles.bar5}
        src={Bar5}
      />
      <img
        style={{
          position: "absolute",
          top: "60%",
          left: "40%",
          width: "25%",
        }}
        className={styles.bar4}
        src={Bar4}
      />
      <img
        style={{
          position: "absolute",
          top: "21%",
          left: "54%",
          width: "27%",
        }}
        className={styles.bar3}
        src={Bar3}
      />
      <img
        style={{
          position: "absolute",
          top: "26%",
          left: "23%",
          width: "30%",
        }}
        className={styles.bar2}
        src={Bar2}
      />
      <img
        style={{
          position: "absolute",
          top: "23%",
          left: "12%",
          width: "28%",
        }}
        className={styles.bar1}
        src={Bar1}
      />
    </div>
  )
}

const Landing = (props) => {

  
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const targetDate = new Date('June 19, 2023 20:00:00').getTime();
      // Get the current date and time
      const currentDate = new Date().getTime();
      // Calculate the remaining time
      const remainingTime = targetDate - currentDate;
      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      // Format the countdown string
      //const countdownString = `${days} days ${hours}h ${minutes}m ${seconds}s`;
      const countdownString = `REVEALED`;
      // Update the countdown state
      setCountdown(countdownString);
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);
  if(props.mobile){
    return(
      <section style={{height: '100vh', width: '100vw', position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: 100, top: 0, left: 0, width: '100%', height: '100%'}}>
        </div>
        <div style={{position: 'relative', zIndex: 1000, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', position: 'relative'}}>
          <div style={{position: 'absolute',zIndex:3, top: 0, left: 0, width: '100%', height: '12vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                <img src={navbarLogo} style={{height: '5vh', width: '5vh', objectFit: 'contain'}}/>
              </div>
              <div
                style={{
                  fontWeight: '700',
                  fontSize: '2.25vh',
                  cursor: 'pointer',
                  marginRight: '2vh'
                }}
                onClick={() => props.setLang(prev => prev === 'en' ? 'es' : 'en')}
              >
                {props.lang.toUpperCase()}
              </div>
            </div>
          </div>
          <Socials mobile={props.mobile}/>
          
          <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        textAlign: "left",
        fontSize: "0.75rem",
        fontFamily: "'Fugaz One'",
      }}
    >
      <img
        style={{
          position: "absolute",
          height: "100vh",
          width: "100%",
          overflow: "hidden",
          objectFit: "cover",
        }}
        alt=""
        src={landingFondo}
      />
      
      <h3
        style={{
          margin: "0",
          position: "absolute",
          height: "8.41%",
          width: "70.41%",
          top: "29%",
          left: "14.36%",
          fontSize: "0.85rem",
          textTransform: "uppercase",
          fontWeight: "500",
          fontFamily: "inherit",
          display: "inline-block",
          textShadow: "0px 2px 4px #000",
        }}
      >
        {props.lang === 'en' ? 'Personalize your robot on' : 'Personaliza tu robot en'}
      </h3>

      <h2
        style={{
          margin: "0",
          position: "absolute",
          top: "30.45%",
          left: "14.36%",
          fontSize: "2.5rem",
          textTransform: "uppercase",
          fontWeight: "400",
          fontFamily: "inherit",
          textShadow: "0px 4px 4px #000",
        }}
      >
        OxySpace
      </h2>

      <h3
        style={{
          margin: "0",
          position: "absolute",
          height: "8.41%",
          width: "66.41%",
          top: "37.26%",
          left: "14.36%",
          fontSize: "inherit",
          textTransform: "uppercase",
          fontWeight: "400",
          fontFamily: "inherit",
          display: "inline-block",
          textShadow: "0px 2px 4px #000",
        }}
      >
        {props.lang === 'en' ? 'Fight with or against others in a world full of dangerous biomes and become the greatest on the battlefield.' : 'Lucha con o contra otros en un mundo lleno de peligrosos biomas y conviértete en el mejor en el campo de batalla.'}
      </h3>
      
    
      <img
        style={{
          position: "absolute",
          top: "calc(50% - 80px)",
          left: "calc(50% + 87px)",
          width: "4.06rem",
          height: "3.69rem",
          objectFit: "cover",
          zIndex: 1
        }}
        className={styles.meteor1}
        src={meteor2}
      />
      <img
        style={{
          position: "absolute",
          top: "calc(50% - 0px)",
          left: "calc(50% - 158px)",
          width: "3.25rem",
          height: "2.75rem",
          objectFit: "cover",
        }}
        className={styles.meteor3}
        src={meteor3}
      />
      <img
        style={{
          position: "absolute",
          top: "calc(50% + 129px)",
          left: "calc(50% - 122px)",
          width: "5rem",
          height: "5.5rem",
          objectFit: "cover",
        }}
        className={styles.meteor2}
        src={meteor4}
      />

    <div style={{
          position: "absolute",
          top: "68%",
          left: "34%",
        }}>
      <button
      onClick={() => {
        scroller.scrollTo('subscribe', {
          duration: 1000,
          smooth: true,
        })
      }}
      type="button" className={styles.btnMobile}>{props.lang === 'en' ? 'JOIN BETA' : 'ÚNETE A LA BETA'}</button>
    </div>

    <div
          style={{
            position: "absolute",
            top: "32rem",
            left: "-8rem",
            zIndex: "10000",
            textTransform: "uppercase",
            display: "inline-block",
            width: "35.13rem",
            textAlign: "center",
            textShadow: "0px 4px 8px #000",
          }}
        >
          <p style={{ margin: "0",fontSize: "0.7rem", }}>{props.lang === 'en' ? `Biome Cinematic` : `Cinematica Bioma`}</p>
          <a href='https://youtu.be/_EmrWW2KPqg'>Click To see the cinematic</a>
        </div>

    <img
        style={{
          position: "absolute",
          top: "82%",
          left: "50%",
          width: "7vh",
          objectFit: "cover",
          opacity: "1"
        }}
        className={styles.meteor2}
        src={scroll}
      />

    </div>
        </div>
        <div style={{position: 'absolute', zIndex: 100, width: '100%', height: '10vh', backdropFilter: 'blur(2px)', bottom: 0}}/>
        <div style={{position: 'absolute', backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #581d66 32.72%, #1d0b31 53.17%, rgba(0, 0, 0, 0) 100%)', zIndex: 1000, width: '100%', height: '32vh', bottom: -120}}/>
      </section>
    )
  }
  
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#fff",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        textAlign: "left",
        fontSize: "2.3rem",
        color: "#fff",
        fontFamily: "'Fugaz One'",
      }}
    >
      <div style={{position: 'absolute', bottom: '15vh', left: "110vh"}}>
            <div style={{position: 'relative'}}>
              <img className={styles.arrowDown1} src={downArrow} style={{height: '20vh',zIndex:"10", filter: 'invert(1)', position: 'absolute', top: '-10vh', left: 0, transform: 'translate(-50%, 0)'}}/>
              <img className={styles.arrowDown2} src={downArrow} style={{height: '20vh',zIndex:"10", filter: 'invert(1)', position: 'absolute', top: '-5vh', left: 0, transform: 'translate(-50%, 0)'}}/>
              <img className={styles.arrowDown3} src={downArrow} style={{height: '20vh',zIndex:"10", filter: 'invert(1)', position: 'absolute', top: '0vh', left: 0, transform: 'translate(-50%, 0)'}}/>
            </div>
      </div>

      <div
        style={{
          position: "absolute",
          height: "100.46%",
          width: "100%",
          top: "0%",
          right: "0%",
          bottom: "-0.46%",
          left: "0%",
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <img
          style={{
            position: "absolute",
              height: "99.54%",
              width: "100%",
              top: "0%",
              right: "0%",
              bottom: "0.46%",
              left: "0%",
              maxWidth: "100%",
              overflow: "hidden",
              maxHeight: "100%",
              objectFit: "cover",
          }}
          alt=""
          src={fondoSolo}
        />
        <img
          style={{
            position: "absolute",
            height: "24.24%",
            width: "10.63%",
            top: "16.68%",
            right: "7.05%",
            bottom: "59.08%",
            left: "82.32%",
            maxWidth: "100%",
            overflow: "hidden",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          className={styles.meteor1}
          src={meteor1}
        />
        <img
          style={{
            position: "absolute",
            height: "15.48%",
            width: "9.69%",
            top: "16.68%",
            right: "49.25%",
            bottom: "67.83%",
            left: "41.06%",
            maxWidth: "100%",
            overflow: "hidden",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          className={styles.meteor2}
          src={meteor2}
        />
        <img
          style={{
            position: "absolute",
            height: "15.02%",
            width: "10.16%",
            top: "47.37%",
            right: "65.33%",
            bottom: "37.6%",
            left: "24.51%",
            maxWidth: "100%",
            overflow: "hidden",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          className={styles.meteor3}
          src={meteor3}
        />
        <img
          style={{
            position: "absolute",
            height: "24.24%",
            width: "12.4%",
            top: "72.17%",
            right: "2.11%",
            bottom: "3.59%",
            left: "85.5%",
            maxWidth: "100%",
            overflow: "hidden",
            maxHeight: "100%",
            objectFit: "cover",
          }}
          className={styles.meteor4}
          src={meteor4}
        />
        <img
          style={{
            position: "absolute",
            height: "99.54%",
            width: "100%",
            top: "0.46%",
            right: "0%",
            bottom: "0%",
            left: "0%",
            overflow: "hidden",
            objectFit: "cover",
          }}
          src={robotSolo}
        />
        <Bars/> //---------------Aqui estan las barras que se mueven
      </div>

      

      <div
        style={{
          position: "absolute",
          top: "18.63rem",
          left: "12.13rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.31rem",
        }}
      >
        <div
          style={{
            position: "relative",
            textTransform: "uppercase",
            display: "inline-block",
            width: "35.13rem",
            textShadow: "0px 4px 8px #000",
          }}
        >
          <p style={{ margin: "0",fontSize: "1.2rem", }}>{props.lang === 'en' ? `Personalize your robot on` : `Personaliza tu robot en`}</p>
          <p style={{ margin: "0",fontSize: "3.8rem", }}>OxySpace</p>
        </div>

        <div
          style={{
            position: "absolute",
            top: "16rem",
            left: "21.5rem",
            zIndex: "10000",
            textTransform: "uppercase",
            display: "inline-block",
            width: "35.13rem",
            textAlign: "center",
            textShadow: "0px 4px 8px #000",
          }}
        >
          <p style={{ margin: "0",fontSize: "1rem", }}>{props.lang === 'en' ? `Biome Cinematic` : `Cinematica Bioma`}</p>
          <a style={{ margin: "0",fontSize: "1rem", marginTop: "0" }} href='https://youtu.be/_EmrWW2KPqg'>Click To see the cinematic</a>
        </div>

        <div
          style={{
            position: "relative",
            fontSize: "1.2rem",
            textTransform: "uppercase",
            color: "rgba(255, 255, 255, 0.9)",
            display: "inline-block",
            width: "30.13rem",
            left: "-2.3rem",
            height: "8.31rem",
            flexShrink: "0",
            textShadow: "0px 4px 8px #000",
          }}
        >
           {props.lang === 'en' ? "Fight with or against others in a world full of dangerous biomes and become the greatest on the battlefield." : 'Lucha con o contra otros en un mundo lleno de peligrosos biomas y conviértete en el mejor en el campo de batalla.'}
        </div>
        <Button
        onClick={() => {
          scroller.scrollTo('subscribe', {
            duration: 1000,
            smooth: true,
          })
        }}
          className={styles.btn}
          variant="primary"
        >
          {props.lang === 'en' ? "JOIN BETA" : "ÚNETE A LA BETA"}
        </Button>
        

      </div>
      <div style={{position: 'absolute', zIndex: 100, width: '100%', height: '8vh', backdropFilter: 'blur(2px)', bottom: 0}}/>
      <div style={{position: 'absolute', backgroundImage: 'linear-gradient(to top, rgb(39 12 48), transparent)', zIndex: 100, width: '100%', height: '32vh', bottom: 0}}/>
      <div style={{position: 'absolute', backgroundImage: 'linear-gradient(to bottom, rgb(39 12 48), transparent)', zIndex: 100, width: '100%', height: '32vh', top: 0}}/>
    </div>
  )
}


export default Landing