import biomaRaroTeam from '../../assets/about/BiomaRaroTeam.png'
import fondoTeam1 from '../../assets/about/FondoTeam1.png'
import fondoTeam2 from '../../assets/about/FondoTeam2.png'
import logoFromdust from '../../assets/about/logofromdust.png'
import robotsGroup from '../../assets/about/RobotsGroup.png'
import teamGroup from '../../assets/about/TeamGroup.png'
import underlineFromDustTeam from '../../assets/about/UnderlineFromDustTeam.svg'

import biomaFuego from '../../assets/features/SinFondo_Fuego.png'
import biomaMagic from '../../assets/features/SinFondo_Magic.png'
import biomaRaro from '../../assets/features/SinFondo_raro.png'
import biomaBosque from '../../assets/features/SinFondoBosque.png'
import blurLine from '../../assets/about/BlurLine.svg'
import rectangle7 from '../../assets/about/Rectangle7.svg'
import rectangle8 from '../../assets/about/Rectangle8.svg'

import damage from '../../assets/features/Damage.png'

import { useInView } from 'react-intersection-observer'

const RobotsGroup = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
      <img src={robotsGroup}
        style={{
          width: '40vw',
          objectFit: 'contain',
          position: 'absolute',
          right: '22vw',
          top: '8vh',
        }}
      />
      <img src={blurLine}
        style={{
          width: '105vw',
          position: 'absolute',
          right: '-10vw',
          top: '39vh',
        }}
      />
    </div>
  );
};

const TeamRoles = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "42rem",
        height: "22.25rem",
        textAlign: "left",
        fontSize: "1rem",
        color: "#fff",
        fontFamily: "'Fugaz One'",
        transform: 'translate(0%, 35%)'
      }}
      id="container"
    >
      <b
        style={{
          position: "absolute",
          height: "10.57%",
          width: "19.93%",
          top: "0%",
          left: "0%",
          fontSize: "1.63rem",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
      >
        ARTISTS
      </b>
      <img
        style={{
          position: "absolute",
          height: "5.93%",
          width: "96.4%",
          top: "9.54%",
          right: "4.6%",
          bottom: "84.54%",
          left: "-1%",
          borderRadius: "20px",
          maxWidth: "100%",
          overflow: "hidden",
          maxHeight: "100%",
        }}
        alt=""
        src={rectangle7}
      />
      <b
        style={{
          position: "absolute",
          height: "32.73%",
          width: "32.38%",
          top: "15.46%",
          left: "0%",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
      >
        <p style={{ margin: "0", color: "#ff9b04" }}>ALBERT GALINDO</p>
        <p style={{ margin: "0" }}>CREATIVE DIRECTOR</p>
        <p style={{ margin: "0" }}>GAME DESIGNER</p>
      </b>
      <b
        style={{
          position: "absolute",
          height: "32.73%",
          width: "31.76%",
          top: "15.46%",
          left: "34.25%",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
      >
        <p style={{ margin: "0", color: "#ff9b04" }}>ALEJANDRO G.</p>
        <p style={{ margin: "0" }}>DESIGNER</p>
        <p style={{ margin: "0" }}>LEVEL DESIGNER</p>
      </b>
      <b
        style={{
          position: "absolute",
          height: "32.73%",
          width: "32.38%",
          top: "16.75%",
          left: "67.62%",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
      >
        <p style={{ margin: "0", color: "#ff9b04" }}>ANTON SHERLOCK</p>
        <p style={{ margin: "0" }}>3D MODELER</p>
        <p style={{ margin: "0" }}>RIGGER</p>
      </b>
      <b
        style={{
          position: "absolute",
          height: "32.99%",
          width: "32.38%",
          top: "67.01%",
          left: "0.12%",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
      >
        <p style={{ margin: "0", color: "#ff9b04" }}>MARCOS GOMEZ</p>
        <p style={{ margin: "0" }}>CEO</p>
        <p style={{ margin: "0" }}>PROGRAMMER</p>
      </b>
      <b
        style={{
          position: "absolute",
          height: "32.99%",
          width: "26.28%",
          top: "67.01%",
          left: "34.37%",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
      >
        <p style={{ margin: "0", color: "#ff9b04" }}>PAU CODERCH</p>
        <p style={{ margin: "0" }}>PRODUCER</p>
        <p style={{ margin: "0" }}>PROGRAMMER</p>
      </b>
      <b
        style={{
          position: "absolute",
          height: "10.57%",
          width: "33.87%",
          top: "51.55%",
          left: "0%",
          fontSize: "1.63rem",
          display: "inline-block",
          textShadow: "0px 4px 8px #000",
        }}
        id="Programmers"
      >
        PROGRAMMERS
      </b>
      <img
        style={{
          position: "absolute",
          height: "5.93%",
          width: "57.17%",
          top: "61.08%",
          right: "43.83%",
          bottom: "32.99%",
          left: "-1%",
          borderRadius: "20px",
          maxWidth: "100%",
          overflow: "hidden",
          maxHeight: "100%",
        }}
        alt=""
        src={rectangle8}
      />
    </div>
  );
};





const ABOUTUS = (props) => {
  const SECTIONS = [
    {
      inView: useInView({ threshold: 0.25, triggerOnce: true }),
      title: {
        en: 'OUR VISION',
        es: 'NUESTRA VISION',
      },
      content: {
        en: [
          [
            "At FromDust, we envision each game as an opportunity to challenge the conventional and explore new frontiers. With features like our rotating biome system in OxySpace, we ensure that each match is a fresh experience. As a studio, we strive to go beyond and deliver memorable and innovative adventures for our audience.",
          ]
        ],
        es: [
          [
            'En FromDust, visualizamos cada juego como una oportunidad para desafiar lo convencional y explorar nuevas fronteras. Con elementos como nuestro sistema de bioma rotativo en OxySpace, garantizamos que cada partida sea una experiencia nueva. Como estudio, buscamos ir más allá y ofrecer aventuras memorables e innovadoras para nuestra audiencia.',
          ]
        ],
        imageSection1: <img src={logoFromdust} 
        style={{
          width: '20vw',
          objectFit: 'contain',
          position: 'absolute',
          left: '58vw',
          top: '40%'}}/>,

          imageExtra:
          <div style={{ position: 'relative', transform: 'translate(00%, 40%)' }}>
            <RobotsGroup /> {/* -------------------------------------------------------- RobotsGroup component */}
          </div>
      
      
      },
      url: {
        en: 'https://oxyspace.app/commingsoon',
        es: 'https://oxyspace.app/commingsoon',
      },
    },
    {
      inView: useInView({ threshold: 0.25, triggerOnce: true }),
      title: {
        en: 'TEAM',
        es: 'EL EQUIPO',
      },
      content: {
        en: [
          [
            'Our team is made up of individuals with unique skills. The designers shape compelling narratives and gameplay, programmers bring these designs to life with creative solutions, while artists craft every detail, from characters to environments, with a distinctive style.'
          ]
        ],
        es: [
          [
            'Nuestro equipo se compone de individuos con habilidades únicas. Los diseñadores moldean narrativas y juego atractivos, los programadores dan vida a estos diseños con soluciones creativas, mientras que los artistas elaboran cada detalle, desde los personajes hasta los entornos, con un estilo inconfundible.'
          ]
        ],
        // image: <img src={oxyImg} style={{height: '80vh', objectFit: 'contain', position: 'absolute', left: '20vw', top: '50%', transform: 'translate(0, -65%)'}}/>,
        
        divRoles:
          <div style={{ position: 'relative' }}>
            <TeamRoles /> {/* -------------------------------------------------------- Roles component */}
          </div>,
        imageBiome:<img src={biomaRaroTeam} 
        style={{
          width: '16vw',
          objectFit: 'contain',
          position: 'absolute',
          left: '22vw',
          top: '40%'}}/>,

        imageTeam:<img src={teamGroup} 
        style={{
          width: '24vw',
          objectFit: 'contain',
          position: 'absolute',
          left: '58vw',
          bottom: '-13%'}}/>,

      },
      url: {
        en: 'https://oxyspace.app/commingsoon',
        es: 'https://oxyspace.app/commingsoon',
      },
    }
  ]



  if(props.mobile){
    return(
      <section style={{
        width: '100vw',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}></section>
    )
  }
    return (
      <section id='about' style={{width: '100%', position: 'relative'}}>
        {
          SECTIONS.map((section, index) => {
            return(
              section.content !== undefined
              ?
              (
                <section
                  key={index}
                  style={{
                    width: '80vw',
                    height: index === 0 ? '50vh' : '90vh',
                    paddingTop: index === 0 ? '20vh' : '20vh',
                    paddingBottom: index === 0 ? '10vh' : '20vh',
                    paddingRight: '10vw',
                    paddingLeft: '10vw',
                    position: 'relative',
                    zIndex: 100,
                    display: 'flex',
                    flexDirection: index % 2 === 0 ? 'row' : 'row-reverse', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontFamily: 'Righteous',
                    textTransform: 'uppercase',
                  }}
                >
                  <div
                    ref={section.inView.ref}
                    style={{
                      maxWidth: '36vw', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent: 'flex-start', 
                      alignItems: index % 2 === 0 ? 'flex-start' : 'flex-start',
                      opacity: section.inView.entry?.isIntersecting ? 1 : 0, 
                      transition: '.8s ease-in-out',
                      transform: `translateX(${section.inView.entry?.isIntersecting ? 0 : index % 2 === 0 ? '-25%' : '25%'})`,
                    }}
                  >
                    
                    <div style={{fontSize: '1rem', fontWeight: '700', color: 'var(--light-blue)', marginBottom: '1vh'}}> {/*DEBERIA SACARLO DEL DIV*/}
                      {props.lang === 'en' ? 'ABOUT' : 'ABOUT'}
                    </div>
                    <div style={{fontWeight: '700', fontSize: '2.8rem', marginBottom: '4vh', textAlign: index % 2 === 0 ? 'start' : 'start'}}>
                      {props.lang === 'en' ? section.title.en : section.title.es}
                    </div>
                    <div style={{position: 'relative'}}>
                      {
                        props.lang === 'en'
                        ?
                        section.content.en[0].map((content, index) => {
                          return(
                            <div
                              key={index}
                              style={{
                                fontSize: '1rem', marginBottom: '2rem', fontWeight: '500',
                                textAlign: SECTIONS.indexOf(section) % 2 === 0 ? 'left' : 'left', //Primero alineado a la izquierda segundo texto a la derecha
                              }}
                            >
                              {content}
                            </div>
                          )
                        })
                        :
                        section.content.es[0].map((content, index) => {
                          return(
                            <div
                              key={index}
                              style={{
                                fontSize: '1rem', marginBottom: '2rem', fontWeight: '500',
                                textAlign: SECTIONS.indexOf(section) % 2 === 0 ? 'left' : 'left', //Primero alineado a la izquierda segundo texto a la derecha
                              }}
                            >
                              {content}
                            </div>
                          )
                        })
                      }
                    </div>
                      {
                        section.url !== undefined
                        &&
                        <a
                          href={props.lang === 'en' ? section.url.en : section.url.es} //BOTONES READMORE
                          rel='noopener noreferrer'
                          target='_blank'
                          className={damage}//para que no pete
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '2px solid var(--light-blue)',
                            height: '1rem',
                            padding: '0.2rem 0.4rem',
                            borderRadius: '1rem',
                            boxShadow: '0 0 5px var(--light-blue), inset 0 0 5px var(--light-blue)',
                          }}
                        >
                          {/* fontWeight managed by parent's css class */}
                          <div style={{fontSize: '.6rem', textAlign: 'center'}}>
                            {props.lang === 'en' ? 'STUDIO WEBSITE' : 'STUDIO WEBSITE'}
                          </div>
                        </a>
                      }
                  </div>
                <div
                  style={{
                    transition: '1s ease-in-out',
                    opacity: section.inView.entry?.isIntersecting ? 1 : 0, //Animacion div imagen
                  }}
                  >
                  {section.content.imageExtra}
                  {section.content.lineSvg}
                  {section.content.imageSection1}

                  {section.content.divRoles}
                  {section.content.imageBiome}
                  {section.content.imageTeam}
                </div>
                

              

                </section> //Seccion imagen
              )
              :
              ( //VERSION MOVILLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL MOVILLLLLLLLLLL
                <section
                  key={index}
                  style={{
                    width: '100vw',
                    height: '350vh',
                    position: 'relative',
                    zIndex: 100,
                  }}
                >
                  <div
                    style={{
                      position: (props.windowOffset >= 2.9 * window.innerHeight && props.windowOffset < 5.5 * window.innerHeight) ? 'fixed' : 'absolute',
                      top: props.windowOffset >= 5.5 * window.innerHeight ? 'unset' : '0',
                      bottom: props.windowOffset >= 5.5 * window.innerHeight ? '0' : 'unset',
                      left: 0,
                      width: '100vw',
                      height: '100vh',
                      zIndex: 1000,
                      overflow: 'hidden',
                    }}
                  >
                    <div ref={section.inView.ref} style={{position: 'absolute', top: '10vh', left: '50%', transform: `translate(-50%, ${section.inView.entry?.isIntersecting ? 0 : 50}%)`, opacity: section.inView.entry?.isIntersecting ? 1 : 0, transition: '.8s ease-in-out'}}>
                      <div style={{fontSize: '1rem', fontWeight: '700', color: 'var(--light-blue)', marginBottom: '1vh', textAlign: 'center'}}>
                        {props.lang === 'en' ? 'FUERA DIV MOBIL?' : 'EL JUEGO'}
                      </div>
                      <div style={{fontWeight: '700', fontSize: '2.25rem', marginBottom: '6vh', textAlign: 'center'}}>
                      {
                        props.lang === 'en'
                        ?
                        SECTIONS[2].subsections[
                          props.windowOffset <= 3.5 * window.innerHeight ? 0 :

                          props.windowOffset <= 4 * window.innerHeight ? 1 :

                          props.windowOffset <= 4.5 * window.innerHeight ? 2 :
                          3
                        ].title.en
                        :
                        SECTIONS[2].subsections[
                          props.windowOffset <= 3.5 * window.innerHeight ? 0 :

                          props.windowOffset <= 4 * window.innerHeight ? 1 :

                          props.windowOffset <= 4.5 * window.innerHeight ? 2 :
                          3
                        ].title.es
                      }
                      </div>
                    </div>
            
                    <div style={{width: '100%', height: '16vh', position: 'absolute', bottom: '0', backgroundImage: 'linear-gradient(to top, #04060a, transparent)', zIndex: 100000}}/>
                  </div>
                  <div style={{width: '100%', height: '16vh', position: 'absolute', bottom: '-16vh', backgroundImage: 'linear-gradient(to bottom, #04060a, transparent)', zIndex: 100}}/>
                </section>
              )
            )
          })
        }
        
        {/*<Roadmap />*/}
      </section>
      
      )
    }

export default ABOUTUS;
